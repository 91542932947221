<template>
  <b-container fluid>
    <b-row>
      <b-col>
         <img :src="$root.$data.portalInfo.LogoUrl" />
        <div v-if="loggingIn">
          <p>Inloggen</p>
        </div>

        <div v-if="!loggingIn">
          <page-snippet name="login" />

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            {{ errorMessage }}
          </div>

          <form>
            <table>
              <tr>
                <td><label for="username">Gebruikersnaam</label></td>
                <td><b-form-input id="username" type="text" v-model="username" required autofocus /></td>
              </tr>
              <tr>
                <td><label for="password">Wachtwoord</label></td>
                <td><b-form-input id="password" type="password" v-model="password" required autofocus /></td>
              </tr>
              <tr>
                <td></td>
                <td><b-button variant="primary" type="submit" @click.prevent="handleSubmit">Inloggen</b-button></td>
              </tr>
            </table>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    name: 'Login',
    components: {
      'page-snippet': () => import('@/components/PageSnippet')
    },
    data () {
      return {
        username: '',
        password: '',
        errorMessage: '',
        loggingIn: false,
      }
    },
    methods: {
      handleSubmit () {
        if (this.password.length > 0) {
          this.loggingIn = true

          this.$http.post('portal/login', {
            username: this.username,
            password: this.password,
            portalId: this.$root.$data.portalInfo.PortalID
          })
          .then(response => {
            sessionStorage.setItem('jwt', response.data.token)
            Cookies.set('jwt', response.data.token) 

            if (response.data.user.userContextToken) {
              sessionStorage.setItem('userContextToken', response.data.user.userContextToken)
            }

            this.$root.$data.me = response.data.user
            this.$root.$data.loggedIn = true

            this.$http.defaults.headers.common.Authorization = 'Bearer ' + response.data.token

            this.$router.push({ name: 'dashboard' })
          })
          .catch((error) => {
            this.loggingIn = false
            if (error.response.status === 403) {
              this.errorMessage = 'Inloggen is niet gelukt. Controleer uw gebruikersnaam en wachtwoord!'
            } else {
              this.errorMessage = 'Er is een onbekende fout opgetreden. Probeer de pagina opnieuw te laden, en opnieuw in te loggen.'
            }
          })
        }
      }
    },
    mounted () {
      if (this.$root.$data.loggedIn) {
        this.$router.push({ name: 'dashboard' })
        return
      }

      const jwt = sessionStorage.getItem('jwt')

      if (jwt != null) {
        this.loggingIn = true
        this.$http.post('portal/login', {
          token: jwt
        })
        .then(response => {
          sessionStorage.setItem('user', JSON.stringify())
          sessionStorage.setItem('jwt', response.data.token)
          Cookies.set('jwt', response.data.token)
          this.$root.$data.me = response.data.user
          this.$root.$data.loggedIn = true

          this.$http.defaults.headers.common.Authorization = 'Bearer ' + response.data.token

          // Check impoersonating
          this.$root.$data.adminToken = sessionStorage.getItem('adminToken')

          if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl)
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch((error) => {
          this.loggingIn = false
          if (error.response.status === 403) {
            this.errorMessage = 'Inloggen is niet gelukt. Controleer uw gebruikersnaam en wachtwoord!'
          } else {
            this.errorMessage = 'Er is een onbekende fout opgetreden. Probeer de pagina opnieuw te laden, en opnieuw in te loggen.'
          }
        })
      } 
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  td:first-child {
    padding-right: 40px; 
  }
</style>
